
import {
    defineComponent, PropType, provide, defineAsyncComponent, ref, watch
} from 'vue';
import { listChooseTagCustomHeader } from '@/components/common/list';
import { updateSortField, getInitSortField, getSortImg } from '@/methods/sort-table';
import { GetRequest, PostRequest, successParam } from '@/api/base';
import {
    community
} from '@/data';
import { systemConfig } from '@/util/system-config';
import ActivityProp from '../activity-prop.vue';
import { initActivityOrRenewToMonth } from './init-choose-user-detail';
import {
    SubscriptionType, UserType, UserItem, ChooseInfo
} from '../../all.type';
import { initRenewToDay } from './init-renew-day-detail';
import { initListData } from './init-choose-user-list-data';

export default defineComponent({
    components: {
        listChooseTagCustomHeader,
        ActivityProp
    },
    props: {
        userType: {
            required: true,
            type: String as PropType<UserType>
        },
        // 是否是批量操作
        isBatch: {
            type: Boolean,
            default: false
        },
        projectId: {
            type: String,
            default: ''
        },
        subscriptionType: {
            required: true,
            type: String as PropType<SubscriptionType>
        },
        initPurchaseDetailsData: {
            type: Object as PropType<Record<string, any>>,
            default: () => ({})
        }
    },
    setup(props, { emit }) {
        const isOpenVirtual = provide('isOpenVirtual', true);
        const autoRenewPendding = ref(true);
        const alreadHasSubscription = ref(false);
        const autoRenewOpType = Object.keys(props.initPurchaseDetailsData).length > 0 ? 'edit' : 'add';
        const date = new Date();
        const m = date.getMonth() + 1;
        const d = date.getDate();
        const curYMD = `${date.getFullYear()}-${m < 10 ? `0${m}` : m}-${d < 10 ? `0${d}` : d}`;

        const autoRenewInfo = ref({
            Users: JSON.stringify([]),
            IntervalType: ((!community.isAutoRenew.value && !systemConfig.closeAutoRenewInArea) || systemConfig.closeAutoRenewInArea) ? 100 : 0,
            Cycles: 0,
            StartTime: curYMD,
            TotalPrice: '',
            SubscriptionUUID: '',
            InstallerUUID: ''
        });
        watch(() => community.isAutoRenew.value, () => {
            autoRenewInfo.value.IntervalType = (
                (!community.isAutoRenew.value && !systemConfig.closeAutoRenewInArea)
                || systemConfig.closeAutoRenewInArea) ? 100 : 0;
        });

        // list所需数据处理
        const {
            row,
            selectType,
            listHeaders,
            requestData,
            updateToList,
            choose,
            chooseDetail,
            communityOption,
            typeOption,
            batchUpdate,
            batchSearchData,
            isShowList,
            timeZoneStr,
            communityChange
        } = initListData(
            props.userType,
            props.isBatch,
            props.subscriptionType as 'active' | 'renewToMonth' | 'renewToDay',
            props.projectId
        );

        // 社区才执行初始化排序
        if (['distributorCom', 'community', 'propertyCommunity'].includes(props.userType)) {
            const initSortRes = getInitSortField(`${props.userType}chooseUser`);
            requestData.param.SortField = initSortRes.sortField;
            requestData.param.Sort = initSortRes.sort;
            updateToList.value += 1;
        }

        // TODO 考虑是否加if判断角色来执行对应的init减少代码执行
        // 激活或者按月续费处理
        const {
            unitPrice,
            count,
            savePrice,
            totalPrice,
            activity,
            activityIds,
            calculation: activityOrRenewToMonthCalculation
        } = initActivityOrRenewToMonth();

        // 按天续费处理
        const {
            renewToDate,
            isCalculating,
            renewAmount,
            unSelectCount,
            isShowUnSelect,
            renewToDateStr,
            initSelectItem,
            unSelectChange,
            disabledDate,
            calculation: renewToDayCalculation,
            isRenewNext
        } = initRenewToDay();

        // 勾选后的处理
        const multipleSelect = (checkKeys: Array<string>, checkRows: Array<UserItem>) => {
            choose.value = checkKeys;
            chooseDetail.value = checkRows;
            if (props.subscriptionType === 'renewToDay') {
                // renew 的处理逻辑
                renewToDayCalculation(checkKeys, checkRows, props.userType, props.projectId);
            } else {
                activityOrRenewToMonthCalculation(checkRows);
            }
        };

        if (autoRenewOpType === 'edit') {
        // eslint-disable-next-line vue/no-setup-props-destructure
            const autoPayInfoDataTemp = props.initPurchaseDetailsData;
            console.log('autoPayInfoDataTemp', autoPayInfoDataTemp);
            autoRenewInfo.value.Users = JSON.stringify(autoPayInfoDataTemp.Items.map((item: any) => item.ID));
            autoRenewInfo.value.IntervalType = autoPayInfoDataTemp.IntervalType;
            autoRenewInfo.value.Cycles = autoPayInfoDataTemp.Cycles;
            autoRenewInfo.value.StartTime = autoPayInfoDataTemp.StartTime;
            autoRenewInfo.value.TotalPrice = autoPayInfoDataTemp.TotalPrice;
            autoRenewInfo.value.SubscriptionUUID = autoPayInfoDataTemp.UUID;
            autoRenewInfo.value.InstallerUUID = props.projectId;
            // if (autoPayInfoDataTemp.Items[0].ProjectUUID !== '') {
            //     const uniqueProjectUUIDs = new Set(autoPayInfoDataTemp.Items.map((item: any) => item.ProjectUUID));
            //     const uniqueArray: any[] = Array.from(uniqueProjectUUIDs);
            //     // formData.value.ProjectUUID = uniqueArray;
            //     // @ts-ignore
            //     requestData.param.ProjectUUID = uniqueArray;
            // }
            // getTableData(true);
            // updateToList.value += 1;
        }

        // 数据处理并进入下一步
        const sureDetail = () => {
            let chooseInfo: ChooseInfo | {} = {};
            if ((props.subscriptionType === 'renewToDay' || props.subscriptionType === 'renewToMonth') && autoRenewOpType === 'edit') {
                // autoRenewInfo.value = {
                //     Users: JSON.stringify([]),
                //     IntervalType: 0,
                //     Cycles: 0,
                //     StartTime: curYMD,
                //     TotalPrice: '',
                //     SubscriptionUUID: '',
                //     InstallerUUID: ''
                // };
                // emit('clearInitPurchaseDetailsData');
            }
            if (props.subscriptionType === 'renewToDay') {
                chooseInfo = {
                    totalPrice: renewAmount.value,
                    count: count.value,
                    usersId: choose.value,
                    item: chooseDetail.value,
                    renewToDate: renewToDateStr.value,
                    autoRenewInfo
                };
            } else {
                chooseInfo = {
                    totalPrice: totalPrice.value,
                    count: count.value,
                    usersId: choose.value,
                    item: chooseDetail.value,
                    autoRenewInfo
                };
            }
            emit('sureDetail', chooseInfo);
        };
        if (Object.keys(props.initPurchaseDetailsData).length > 0) {
            // autoRenew-1-6:autoRenew编辑勾选
            setTimeout(() => {
                GetRequest(requestData.url, requestData.param, (res: any) => {
                    console.log('res', res);
                    const alreadSelect: any[] = [];
                    props.initPurchaseDetailsData.Items.forEach((item: any) => {
                        const index = res.row.findIndex((el: any) => el.ID === item.ID);
                        console.log('index', index);
                        if (index > -1) {
                            alreadSelect.push(res.row[index]);
                        }
                    });
                    chooseDetail.value = alreadSelect;
                    initSelectItem.value = alreadSelect.map((item) => item.ID);
                });
            }, 1500);
        }

        // 获取排序方式， 更新表格数据
        function getSortRes(table: string, sortField: string) {
            const {
                sort
            } = updateSortField(table, sortField);
            requestData.param.SortField = sortField;
            requestData.param.Sort = sort;
            updateToList.value += 1;
        }
        const autoRenewConfigComponent = {
            propertyCommunity: defineAsyncComponent(() => import('./auto-renew-config-community.vue')),
            propertyOffice: defineAsyncComponent(() => import('./auto-renew-config-office.vue'))
        };

        return {
            row,
            selectType,
            listHeaders,
            requestData,
            updateToList,
            multipleSelect,
            renewToDate,
            isCalculating,
            renewAmount,
            unSelectCount,
            isShowUnSelect,
            renewToDateStr,
            initSelectItem,
            unSelectChange,
            disabledDate,
            renewToDayCalculation,
            isRenewNext,
            choose,
            chooseDetail,
            unitPrice,
            count,
            savePrice,
            totalPrice,
            activity,
            activityIds,
            activityOrRenewToMonthCalculation,
            sureDetail,
            communityOption,
            typeOption,
            batchUpdate,
            batchSearchData,
            isShowList,
            communityChange,
            timeZoneStr,
            isOpenVirtual,
            getSortImg,
            getSortRes,
            autoRenewConfigComponent,
            autoRenewPendding,
            autoRenewInfo,
            alreadHasSubscription,
            autoRenewOpType,
            community,
            systemConfig
        };
    }
});
