
import { defineComponent, PropType } from 'vue';
import { SubscriptionType, UserType } from '../../all.type';
import FeatureDetail from './feature-detail.vue';
import ChooseUser from './choose-user.vue';

export default defineComponent({
    components: {
        FeatureDetail,
        ChooseUser
    },
    props: {
        subscriptionType: {
            required: true,
            type: String as PropType<SubscriptionType>
        },
        userType: {
            required: true,
            type: String as PropType<UserType>
        },
        projectId: {
            type: String,
            default: ''
        },
        // 是否是批量操作
        isBatch: {
            type: Boolean,
            default: false
        },
        initPurchaseDetailsData: {
            type: Object as PropType<Record<string, any>>,
            default: () => ({})
        }
    }
});
