type SortTable = {
    [key in string]: {
        [key in string]: string;
    } & {
        currentField: string;
    }
}

// 获取排序方式
export function updateSortField(table: string, sortField: string): {sortField: string; sort: string} {
    const allTables = localStorage.getItem('sortTable');
    if (allTables) {
        const sortInfo: SortTable = JSON.parse(allTables);
        // 判断当前表格是否有使用过当前排序字段，没有的话默认升序
        if (sortInfo[table]) {
            if (sortInfo[table].currentField !== sortField && !sortInfo[table][sortField]) {
                sortInfo[table][sortField] = 'asc';
            } else if (sortInfo[table].currentField === sortField) {
                sortInfo[table][sortField] = sortInfo[table][sortField] === 'asc' ? 'desc' : 'asc';
            }
            sortInfo[table].currentField = sortField;
        } else {
            sortInfo[table] = {
                [sortField]: 'asc',
                currentField: sortField
            };
        }
        localStorage.setItem('sortTable', JSON.stringify(sortInfo));
        return {
            sortField,
            sort: sortInfo[table][sortField]
        };
    }
    localStorage.setItem('sortTable', JSON.stringify({
        [table]: {
            [sortField]: 'asc',
            currentField: sortField
        }
    }));
    return {
        sortField,
        sort: 'asc'
    };
}
// 获取当前表格的排序字段和方式
export function getInitSortField(table: string): {sortField: string; sort: string} {
    const allTables = localStorage.getItem('sortTable');
    if (allTables) {
        const sortInfo = JSON.parse(allTables);
        if (sortInfo[table] && sortInfo[table].currentField) {
            return {
                sortField: sortInfo[table].currentField,
                sort: sortInfo[table][sortInfo[table].currentField]
            };
        }
    }
    return {
        sortField: '',
        sort: ''
    };
}
// 获取当前排序图标
export function getSortImg(table: string, sortField: string) {
    const allTables = localStorage.getItem('sortTable');
    if (allTables) {
        const sortInfo = JSON.parse(allTables);
        if (sortInfo[table] && sortInfo[table].currentField === sortField) {
            return sortInfo[table][sortField] === 'asc' ? require('@/assets/image/sort-asc.png') : require('@/assets/image/sort-desc.png');
        }
    }
    return require('@/assets/image/sort-unselect.png');
}