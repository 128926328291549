import { ref } from 'vue';

const initBillInfo = () => {
    // 检查账单信息是否全部填写
    const isBillInfoFilled = ref(false);
    const billInfoFilled = (isFilled: boolean) => {
        isBillInfoFilled.value = isFilled;
    };

    return {
        isBillInfoFilled,
        billInfoFilled
    };
};
export default null;
export {
    initBillInfo
};