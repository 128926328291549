import { computed, ref, watch } from 'vue';
import moment from 'moment';
import { format } from '@/methods/date';
import { subscriptionApi } from '@/api';
import { UserType, UserItem } from '../../all.type';

// 按天续费
const initRenewToDay = () => {
    // 存放RenewTo时间组件的值
    const renewToDate = ref<null | Date>(null);
    const renewToDateStr = ref('');
    // 是否在计算中
    const isCalculating = ref(false);
    // renew金额
    const renewAmount = ref(0);
    // 所选数据是否都大于过期时间
    const isShowUnSelect = ref(false);
    // 所选数据大于RenewTo的时间
    const unSelectCount = ref(0);
    // 所选数据大于RenewTo的时间的ID
    const selectIds = ref<Array<number>>([]);
    // 存放初始化勾选的值
    const initSelectItem = ref<Array<number>>([]);
    const timer = ref<any>(null);
    // 控制能否下一步
    const isRenewNext = ref(true);

    // 时间组件只能选明天或者五年后
    const disabledDate = (time: Date) => {
        const timeUtil = new Date();
        const beginTime = timeUtil.setDate(timeUtil.getDate());
        const endTime = moment(timeUtil).add(5, 'y').valueOf();
        return time.getTime() < beginTime || time.getTime() > endTime;
    };

    const debounce = (fu: Function, wait: number) => () => {
        if (timer.value !== null) {
            clearTimeout(timer.value);
        }
        timer.value = setTimeout(fu, wait);
    };

    // 计算金额
    const calculation = (
        checkKeys?: Array<string>,
        checkRows?: Array<UserItem>,
        userType?: UserType,
        projectId?: string
    ) => {
        // 设置定时器
        isCalculating.value = true;
        isRenewNext.value = true;
        const ids = checkKeys instanceof Array ? checkKeys : [];
        const rows = checkRows instanceof Array ? checkRows : [];

        // 数据判空处理
        if (renewToDate.value === null || ids.length === 0) {
            isCalculating.value = false;
            renewAmount.value = 0;
            isShowUnSelect.value = false;
            clearTimeout(timer.value);
            return true;
        }

        // 控制unselect显示
        unSelectCount.value = 0;
        isShowUnSelect.value = false;
        selectIds.value = [];
        rows.forEach((row) => {
            const copyTimeZoneExpireTime = row.TimeZoneExpireTime.replaceAll('-', '/');
            const copyRenewToDateStr = renewToDateStr.value.replaceAll('-', '/');
            if (format(new Date(copyTimeZoneExpireTime), 'X') >= format(new Date(copyRenewToDateStr), 'X')) {
                isShowUnSelect.value = true;
                unSelectCount.value += 1;
            } else {
                selectIds.value.push(row.ID);
            }
        });

        if (isShowUnSelect.value) {
            renewAmount.value = 0;
            isCalculating.value = false;
            clearTimeout(timer.value);
            return true;
        }

        // 防抖
        debounce(() => {
            // 请求参数
            const param: {NextTime: string;Users: string ;OperateId?: string} = {
                NextTime: renewToDateStr.value,
                Users: JSON.stringify(selectIds.value)
            };
            // dis 操作携带 projectId
            if (userType === 'distributorCom') {
                param.OperateId = projectId;
            }
            // 显示计算中
            isCalculating.value = true;
            subscriptionApi.getComputedPrice([param, false], [(data: {Price: number}) => {
                renewAmount.value = data.Price;
                isCalculating.value = false;
                isRenewNext.value = false;
            }, false]);
        }, 1500)();

        return true;
    };

    // 清空日期操作
    watch(computed(() => renewToDate.value), (val) => {
        if (val === null) {
            renewAmount.value = 0;
            isShowUnSelect.value = false;
        } else {
            isCalculating.value = true;
            renewToDateStr.value = format(val, 'YYYY-MM-DD');
            calculation();
        }
    });

    const unSelectChange = () => {
        initSelectItem.value = selectIds.value;
    };

    return {
        disabledDate,
        renewToDate,
        isCalculating,
        renewAmount,
        calculation,
        isShowUnSelect,
        renewToDateStr,
        unSelectCount,
        selectIds,
        initSelectItem,
        unSelectChange,
        isRenewNext
    };
};

export default null;
export {
    initRenewToDay
};
