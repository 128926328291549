import {
    reactive, ref, Ref
} from 'vue';
import { RequestData } from '@/components/common/list';
import { format } from '@/methods/date';
import { CommunityList } from '@/components/view/common/subscription/module/purchase-detail/all.type';
import Notice from '@/util/notice.base';
import {
    getUrl, getUserHeader, getUrlParams
} from '../../init-all-data';
import { UserType, UserItem } from '../../all.type';
import subscriptionApi from '../../../../../../api/common/subscription';

// 初始化基本数据如列表头 页面词条等
const initListData = (
    userType: UserType,
    isBatch: boolean,
    subscriptionType: 'active' | 'renewToMonth' | 'renewToDay',
    projectId: string
) => {
    // 行数
    const row = ref(3000);

    // const selectType = getSelectType(userType); TODOv6.4统一词条，如有角色对应解开注释修改此方法对应角色词条
    const selectType = WordList.SubscriptionSelect;
    // 列表头
    const listHeaders = getUserHeader(userType, isBatch, subscriptionType, 'choose');
    // 列表请求配置
    const requestData: RequestData = reactive({
        url: getUrl(userType, subscriptionType),
        param: getUrlParams(userType, subscriptionType, projectId, isBatch)
    });
    const updateToList = ref(0);

    const batchSearchData = reactive<{
        url: string;
        param: {
            ProjectUUID: string[];
            Type: string;
            InstallerUUID: string;
            BeginTime: string;
            EndTime: string;};
    }>({
        url: getUrl(userType, subscriptionType),
        param: {
            ProjectUUID: [],
            Type: 'all',
            InstallerUUID: projectId,
            BeginTime: '',
            EndTime: ''
        }
    });
    // 控制是否显示列表，社区没有选择的时候提示
    const isShowList = ref(true);
    const isShowListFun = () => {
        if (isBatch && subscriptionType === 'renewToDay') {
            isShowList.value = batchSearchData.param.ProjectUUID.length > 0;
        }
    };
    isShowListFun();

    const batchUpdate = () => {
        isShowListFun();
        requestData.param.IsBatch = true;
        requestData.param.ProjectUUID = batchSearchData.param.ProjectUUID.join(';');
        requestData.param.Type = batchSearchData.param.Type;
        requestData.param.BeginTime = format(batchSearchData.param.BeginTime as string);
        requestData.param.EndTime = format(batchSearchData.param.EndTime as string);
        updateToList.value += 1;
    };

    // 勾选数据存储
    const choose: Ref<string[]> = ref([]);
    const chooseDetail: Ref<UserItem[]> = ref([]);

    // 批量操作需要过滤筛选
    // 社区列表
    const communityOption = ref<{Name: string;UUID: string}[]>([]);
    const allCommunityDetail = ref<CommunityList[]|[]>([]);
    if (isBatch) {
        // TODO
        let params = {};
        if (userType === 'distributorCom') {
            params = {
                InstallerUUID: projectId
            };
        }
        subscriptionApi.getCommunityList({ ...params, SortField: 'Location', Sort: 'asc' }, (res: any) => {
            allCommunityDetail.value = res.row;
            console.log('allCommunityDetail.value', allCommunityDetail.value);
            res.row.forEach((community: any) => {
                communityOption.value.push({
                    Name: community.Location,
                    UUID: community.UUID
                });
            });
        });
    }

    // 批量社区搜索后的处理
    const timeZoneStr = ref('');
    const communityChange = (val: string[]) => {
        console.log(val);
        // 只有续费有时区问题
        if (subscriptionType === 'active') {
            return;
        }
        if (batchSearchData.param.ProjectUUID.length === 0) {
            timeZoneStr.value = '';
        } else if (batchSearchData.param.ProjectUUID.length === 1) {
            allCommunityDetail.value.forEach((community: CommunityList) => {
                if (community.UUID === val[0]) {
                    timeZoneStr.value = community.TimeZoneNumber;
                }
            });
        } else {
            allCommunityDetail.value.forEach((community: CommunityList) => {
                if (community.UUID === val[val.length - 1] && timeZoneStr.value !== community.TimeZoneNumber) {
                    Notice.messageBox(
                        'alert',
                        WordList.ProperAllTextDiffCommunityTimeZone,
                        '',
                        'warning'
                    )(() => true);
                    batchSearchData.param.ProjectUUID.splice(val.length - 1, 1);
                }
            });
        }
    };

    // 查询用户类型列表
    const typeOption = ref([
        { key: '0', label: WordList.RDeviceSearchLabelRoomName },
        { key: '1', label: WordList.PmApp },
        { key: '2', label: WordList.AdditionalApp }
    ]);
    if (subscriptionType !== 'active') {
        typeOption.value.splice(2, 1);
    }

    return {
        row,
        selectType,
        listHeaders,
        requestData,
        updateToList,
        choose,
        chooseDetail,
        communityOption,
        typeOption,
        batchUpdate,
        batchSearchData,
        isShowList,
        timeZoneStr,
        communityChange
    };
};
export default null;
export {
    initListData
};
