const discountTotalCount = (unit: number, count: number): number => (Math.round(unit * 100) * count) / 100;

const discountUnitTo100 = (unit: number): number => Math.round(unit * 100);

const discountUnitEx100 = (unit: number): number => unit / 100;

const diffOnePrice = (
    total: number, coupon: number, before: number
): boolean => discountUnitTo100(before) === discountUnitTo100(total) + discountUnitTo100(coupon);

export default null;
export {
    discountTotalCount,
    discountUnitTo100,
    discountUnitEx100,
    diffOnePrice
};