import { ref, watch, onBeforeUnmount } from 'vue';
import { discountTotalCount } from '@/methods/count';
import { subscriptionApi } from '@/api';
import * as SubscriptionInterface from '../../all.type';
import { FeeType, typeForMonthly } from '../../init-all-data';

const initFeature = () => {
    // 高级功能名称
    const featureName = ref('');
    // 高级功能收费类型：按月续费 和 一次性付费
    const featureFeeType = ref(0);
    // 收费金额
    const featurePrice = ref(0);
    // 高级功能过期时间
    const featureExpireTime = ref('');
    // 月数
    const featureCount = ref(0);
    // 总金额
    const totalPrice = ref(0);
    const stop = watch(() => featureCount.value, (count) => {
        totalPrice.value = discountTotalCount(featurePrice.value, count);
    });

    onBeforeUnmount(() => {
        stop();
    });

    // 控制月份选择是否展示：一次性收费不展示，按月收费展示
    const showMonth = ref(false);

    // 获取收费信息
    const getFeaturePlan = (
        userType: SubscriptionInterface.UserType,
        projectId: string
    ) => {
        let params = {};
        // dis特殊携带请求数据
        if (userType === 'distributorOffice') {
            params = {
                CommunityId: projectId.split(';')[1]
            };
        }
        subscriptionApi.getFeaturePlanFee(params, (res: {
            FeatureFee: number;
            FeatureFeeType: number;
            FeatureName: string;
            CommunityID: string;
            FeatureExpireTime: string;
        }) => {
            featureName.value = res.FeatureName;
            featurePrice.value = res.FeatureFee;
            featureFeeType.value = res.FeatureFeeType;
            featureExpireTime.value = res.FeatureExpireTime;
            // 月费按照之前逻辑，一次性收费直接为获取fee
            if (featureFeeType.value === typeForMonthly) {
                totalPrice.value = discountTotalCount(featurePrice.value, featureCount.value);
            } else {
                totalPrice.value = featurePrice.value;
            }
            showMonth.value = Number(featureFeeType.value) === typeForMonthly;
        });
    };

    return {
        FeeType,
        featureName,
        featureFeeType,
        featurePrice,
        featureExpireTime,
        featureCount,
        totalPrice,
        showMonth,
        getFeaturePlan
    };
};

export default initFeature;