import { ChangeWordList } from '@/methods/setText';
import { officeId } from '@/data/src/office';
import {
    UserType, HeadersListType, HeadersList, BaseHeadersListType,
    BaseHeadersList, ReturnHeaderList, SubscriptionType
} from './all.type';

const payType = {
    active: 0,
    renewToDay: 1,
    renewToMonth: 1,
    payApp: 2
};

// 角色分组
const communityTypeGroup = ['distributorCom', 'community', 'propertyCommunity'];
const officeTypeGroup = ['distributorOffice', 'office'];
const singleTypeGroup = ['distributorIns', 'installer'];

const renewType = ['renewToDay', 'renewToMonth'];

// 激活卡片词条根据角色修改
const getActiveType = (type: UserType) => ({
    distributorIns: WordList.ProperAllTextActivateNewHouseholds,
    distributorCom: WordList.ProperAllTextActivation,
    distributorOffice: WordList.ProperAllTextActivatePeople,
    installer: WordList.ProperAllTextActivateNewHouseholds,
    propertyCommunity: WordList.ProperAllTextActivation,
    community: ChangeWordList.SubscriptionActivate,
    office: ChangeWordList.SubscriptionActivate
}[type]);

// 激活续费操作根绝角色修改title select词条
const getSelectType = (type: UserType) => ({
    distributorIns: WordList.ProperAllTextNewSelectEndUsers,
    distributorCom: WordList.ProperAllTextNewSelectApartments,
    distributorOffice: WordList.ProperAllTextSelectPeople,
    installer: WordList.ProperAllTextNewSelectEndUsers,
    propertyCommunity: WordList.ProperAllTextNewSelectApartments,
    community: ChangeWordList.SubscriptionSelectType,
    office: ChangeWordList.SubscriptionSelectType
}[type]);

/**
 * @description 获取激活操作的列表头
 * @param userType 用户类型UserType
 * @param isBatch 是否批量
 * @param subscriptionType 操作类型
 * @param step 付费步骤
 * @return ReturnHeaderList
 * @author zyc
 * @lastEditor zyc
 * @lastEditTime 2022/3/31
 */
const getUserHeader = (
    userType: UserType,
    isBatch: boolean,
    subscriptionType: 'active' | 'renewToMonth' | 'renewToDay',
    step: 'choose' | 'info'
): ReturnHeaderList => {
    // TODO 该函数修改注意returnHeadersList的插入顺序，重要的事说三遍
    // TODO 该函数修改注意returnHeadersList的插入顺序，重要的事说三遍
    // TODO 该函数修改注意returnHeadersList的插入顺序，重要的事说三遍
    const headersList: HeadersList = {
        unitName: {
            name: 'UnitName',
            label: WordList.DeviceDetailDetailUnitName
        },
        roomName: {
            name: 'RoomName',
            label: WordList.RDeviceSearchLabelRoomName,
            headerType: 'customize'
        },
        roomNumber: {
            name: 'RoomNumber',
            label: WordList.RDeviceSearchLabelRoomNumber,
            headerType: 'customize'
        },
        email: {
            name: 'Email',
            label: WordList.TabelPropertyManageEmail
        },
        name: {
            name: 'Name',
            label: WordList.TabelPersonUserInHtmlName,
            type: 'customize'
        },

        departmentName: {
            name: 'DepartmentName',
            label: WordList.ProperAllTextDepartment
        }
    };
    const baseHeaderList: BaseHeadersList = {
        active: {
            name: 'Amcount',
            label: `${WordList.ProperAllTextActivationFee}($)`
        },
        renew: {
            name: 'Amcount',
            label: `${WordList.ProperAllTextPackageFee}($)`
        },
        expireTime: {
            name: 'ExpireTime',
            label: WordList.TabelExpretimeBoxSpecificTime
        },
        nextExpireTime: {
            name: 'ShowNextExpireTime',
            label: WordList.ProperAllTextNextExpireTime
        }
    };

    // 注意顺序 根据操作对象（社区，办公，单住户）的表头
    const community: HeadersListType[] = ['unitName', 'roomName', 'roomNumber', 'email', 'name'];
    const installer: HeadersListType[] = ['email', 'name'];
    const office: HeadersListType[] = ['departmentName', 'email', 'name'];

    // 注意顺需 根据激活续费操作的表头
    const active: BaseHeadersListType[] = ['active'];
    const renew: BaseHeadersListType[] = ['renew', 'expireTime'];

    // 返回数据处理
    const returnHeaderList: ReturnHeaderList[] = [];
    // 根据是否批量添加第一个表头
    if (isBatch) {
        // TODO 根据后端接口修改name
        returnHeaderList.push({
            name: 'CommunityName',
            label: WordList.ProperAllCommunity
        });
    }
    // 根据操作对象（社区，办公，单住户）的表头
    if (communityTypeGroup.includes(userType)) {
        community.forEach((key: HeadersListType) => {
            returnHeaderList.push(headersList[key]);
        });
    } else if (officeTypeGroup.includes(userType)) {
        office.forEach((key: HeadersListType) => {
            returnHeaderList.push(headersList[key]);
        });
    } else {
        installer.forEach((key: HeadersListType) => {
            returnHeaderList.push(headersList[key]);
        });
    }
    // 根绝操作激活续费的表头
    if (subscriptionType === 'active') {
        active.forEach((key: BaseHeadersListType) => {
            returnHeaderList.push(baseHeaderList[key]);
        });
    } else {
        renew.forEach((key: BaseHeadersListType) => {
            returnHeaderList.push(baseHeaderList[key]);
        });
    }

    // 根据页面进度的表头
    if (step === 'choose') {
        return returnHeaderList;
    }

    // info 页面增加下一次过期时间
    returnHeaderList.push(baseHeaderList.nextExpireTime);
    return returnHeaderList;
};

// TODO要修改新接口 区别dis、办公及其他请求参数
/**
 * @param userType
 */
const activeUrl = {
    distributorIns: 'v3/web/single/user/getActiveInfoList',
    distributorCom: 'v3/web/community/user/getActiveInfoList',
    distributorOffice: 'v3/web/office/user/getOfficeActiveUser',
    installer: 'v3/web/single/user/getActiveInfoList',
    propertyCommunity: 'v3/web/community/user/getActiveInfoList',
    community: 'v3/web/community/user/getActiveInfoList',
    office: 'v3/web/office/user/getOfficeActiveUser'
};
const renewUrl = {
    distributorIns: 'v3/web/single/user/getReNewInfoList',
    distributorCom: 'v3/web/community/user/getReNewInfoList',
    distributorOffice: 'v3/web/office/user/getOfficeActiveUser',
    installer: 'v3/web/single/user/getReNewInfoList',
    propertyCommunity: 'v3/web/community/user/getReNewInfoList',
    community: 'v3/web/community/user/getReNewInfoList',
    office: 'v3/web/office/user/getOfficeActiveUser'
};
const getUrl = (
    userType: UserType,
    subscriptionType: 'active' | 'renewToMonth' | 'renewToDay'
) => {
    if (subscriptionType === 'active') {
        return activeUrl[userType];
    }
    return renewUrl[userType];
};

/**
 * @param userType 操作者类别
 * @param subscriptionType 操作类别：激活续费
 * @param projectId 项目ID
 * @param isBatch 是否批量
 */
const getUrlParams = (
    userType: UserType,
    subscriptionType: 'active' | 'renewToMonth' | 'renewToDay',
    projectId: string,
    isBatch = false
) => {
    // TODO 有新接口到时候需要修改
    let params: {
        [key in string]: string|boolean;
    } = {};
    if (communityTypeGroup.includes(userType)) {
        params = {
            IsBatch: isBatch,
            ProjectUUID: '',
            Type: 'all',
            InstallerUUID: '',
            BeginTime: '',
            EndTime: ''
        };
        if (isBatch) {
            params.InstallerUUID = projectId;
        }
    }

    if (officeTypeGroup.includes(userType)) {
        // 办公需要携带办公的UUID请求
        params = {
            Active: subscriptionType === 'active' ? '0' : '1',
            OfficeId: userType === 'distributorOffice' ? projectId.split(';')[0] : officeId.value
        };
    }

    if (singleTypeGroup.includes(userType)) {
        if (userType === 'distributorIns') {
            params.InstallerUUID = projectId;
        }
    }

    return params;
};

// 下载发票地址
const getReceiptUrl = (userType: UserType) => ({
    distributorIns: 'v3/web/common/order/getReceipt',
    distributorCom: 'v3/web/common/order/getReceipt',
    distributorOffice: 'v3/web/common/order/getReceipt',
    installer: 'v3/web/common/order/getReceipt',
    propertyCommunity: 'v3/web/common/order/getReceipt',
    community: 'v3/web/common/order/getReceipt',
    office: 'v3/web/common/order/getReceipt'
}[userType]);

// 创建订单地址
const createOrderUrl = {
    community: 'v3/web/community/order/create',
    office: 'v3/web/office/order/create',
    single: 'v3/web/single/order/create'

};
const getCreateOrderUrl = (userType: UserType, subscriptionType: SubscriptionType) => {
    if (communityTypeGroup.includes(userType)) {
        return createOrderUrl.community;
    } if (officeTypeGroup.includes(userType)) {
        if (subscriptionType === 'featurePlan') {
            return userType === 'distributorOffice' ? 'discreateofficeorder' : 'communitycreateorder';
        }
        return createOrderUrl.office;
    }
    return createOrderUrl.single;
};

// 高级功能部分begin
const FeeType = [WordList.ProperAllTextOneTime, WordList.ProperAllTextMonthly];
const typeForOneTime = 0;
const typeForMonthly = 1;

// featurePlan 表头
const getFeaturePlanHeaders = (type: 'oneTime' | 'monthly') => {
    const baseHeaders = [{
        name: 'FeaturePlan',
        label: WordList.ProperAllTextFeaturePlan
    }, {
        name: 'FeeType',
        label: WordList.ProperAllTextFeeType,
        type: 'customize'
    }, {
        name: 'Fee',
        label: `${WordList.Fee}($)`
    }];
    const monthlyHeader = [{
        name: 'ExpireTime',
        label: WordList.TabelExpretimeBoxSpecificTime
    }, {
        name: 'NextExpireTime',
        label: WordList.ProperAllTextNextExpireTime
    }];
    if (type === 'oneTime') {
        return baseHeaders;
    }
    return baseHeaders.concat(monthlyHeader);
};
// 高级功能部分end

export default null;
export {
    getActiveType,
    getSelectType,
    getUserHeader,
    getUrl,
    getUrlParams,
    getFeaturePlanHeaders,
    getReceiptUrl,
    getCreateOrderUrl,
    communityTypeGroup,
    officeTypeGroup,
    singleTypeGroup,
    FeeType,
    typeForOneTime,
    typeForMonthly,
    renewType,
    payType
};
