import { ref } from 'vue';
import { subscriptionApi } from '@/api';
import Notice from '@/util/notice.base';
import {
    getActiveType, officeTypeGroup, communityTypeGroup
} from '../../init-all-data';
import * as SubscriptionInterface from '../../all.type';
import { UserType } from '../../all.type';

// v6.4 控制支付类型的设置 zyc 2022/3/30
const initChooseType = (
    userType: UserType,
    projectId: string,
    emit: any
) => {
    // const ActiveType = getActiveType(userType); TODO如有变更可在此函数修改对应词条
    const ActiveType = WordList.TabelDeviceInHtmlActivate;
    const showFeaturePlan = ref(false);
    const isFeaturePermanent = ref(false);

    // 高级功能的判断是否可点击
    if (officeTypeGroup.includes(userType)) {
        let params = {};
        // distributor身份携带id查询高级收费
        if (userType === 'distributorOffice') {
            params = {
                CommunityId: projectId.split(';')[1]
            };
        }
        subscriptionApi.getShowFeaturePlan(params, (res: {
            ShowFeaturePlan: boolean;
            IsPermanent: boolean;
        }) => {
            // 6.2之前、installer/distributorIns不显示
            showFeaturePlan.value = res.ShowFeaturePlan;
            isFeaturePermanent.value = res.IsPermanent;
        });
    }

    // 选择激活
    const chooseActive = () => {
        emit('click', 'active');
    };

    // 选择续费
    const chooseRenew = () => {
        let renewType: SubscriptionInterface.SubscriptionType = 'renewToMonth';
        if (communityTypeGroup.includes(userType)) {
            renewType = 'renewToDay';
        }
        emit('click', renewType);
    };

    // 选择高级功能
    const chooseFeature = () => {
        // 高级功能永久使用提示
        if (isFeaturePermanent.value) {
            Notice.messageBox(
                'alert',
                WordList.ProperAllTextFeaturePaidTips,
                WordList.ProperAllTextReminding,
                undefined
            )(() => false);
        } else {
            emit('click', 'featurePlan');
        }
    };

    return {
        ActiveType,
        showFeaturePlan,
        chooseActive,
        chooseRenew,
        chooseFeature
    };
};

export default initChooseType;
