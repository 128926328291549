
import { defineComponent, PropType } from 'vue';
import { UserType, DetailInfo } from '../../all.type';
import initFeature from './init-feature-detail';

export default defineComponent({
    props: {
        userType: {
            required: true,
            type: String as PropType<UserType>
        },
        projectId: {
            type: String,
            default: ''
        }
    },
    setup(props, { emit }) {
        const {
            FeeType,
            featureName,
            featureFeeType,
            featurePrice,
            featureExpireTime,
            featureCount,
            totalPrice,
            showMonth,
            getFeaturePlan
        } = initFeature();

        // 获取收费信息
        getFeaturePlan(props.userType, props.projectId);

        const sureDetail = () => {
            let userId: string[] = [];
            if (props.userType === 'distributorOffice') {
                userId = [props.projectId.split(';')[1]];
            }
            const featureInfo: DetailInfo = {
                totalPrice: totalPrice.value,
                count: featureCount.value,
                item: {
                    FeaturePlan: featureName.value,
                    FeeType: featureFeeType.value,
                    Fee: featurePrice.value,
                    ExpireTime: featureExpireTime.value
                },
                usersId: userId
            };
            emit('sureDetail', featureInfo);
        };

        return {
            featureName,
            featureFeeType,
            featurePrice,
            FeeType,
            featureExpireTime,
            featureCount,
            totalPrice,
            showMonth,
            sureDetail
        };
    }
});
