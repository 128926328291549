
import {
    defineComponent, PropType, watch, defineAsyncComponent
} from 'vue';
import { imgText } from '@/components/common/card';
import initChooseType from './init-choose-type';
import { UserType } from '../../all.type';

export default defineComponent({
    emits: ['click'],
    components: {
        imgText
    },
    props: {
        userType: {
            required: true,
            type: String as PropType<UserType>
        },
        // 非必需，dis身份需要操作的projectId查询
        projectId: {
            type: String,
            default: ''
        }
    },
    setup(props, { emit }) {
        const propsUserType = props.userType;
        const propsProjectId = props.projectId;

        const {
            ActiveType,
            showFeaturePlan,
            chooseActive,
            chooseRenew,
            chooseFeature
        } = initChooseType(propsUserType, propsProjectId, emit);
        const autoPayRecordComponent = {
            propertyCommunity: defineAsyncComponent(() => import('../auto-pay-record-community-pm.vue')),
            propertyOffice: defineAsyncComponent(() => import('../auto-pay-record-office-pm.vue'))
        };
        function changeStep(stepData: {type: string; to: string; data: any}) {
            // @ts-ignore
            emit('changeStep', stepData);
        }
        return {
            ActiveType,
            showFeaturePlan,
            chooseActive,
            chooseRenew,
            chooseFeature,
            autoPayRecordComponent,
            changeStep
        };
    }
});
