import { Ref, ref } from 'vue';
import { getNextExpireTime, getCustomizeFormToDate, getCustomizeForm } from '@/methods/date';
import Notice from '@/util/notice.base';
import { subscriptionApi } from '@/api';
import HttpRequest from '@/util/axios.config';
import moment from 'moment';
import {
    getReceiptUrl, getCreateOrderUrl, communityTypeGroup, singleTypeGroup, typeForMonthly
} from './init-all-data';
import {
    ChooseInfo, FeatureItem, SubscriptionType, UserItem, UserType
} from './all.type';

const initPay = (
    setActiveStep: Function,
    subscriptionType: Ref<SubscriptionType>,
    userType: UserType,
    projectId: string,
    isBatch: boolean,
    isAutoRenew: Ref<boolean>,
    autoRenewOpType: Ref<string>
) => {
    // 处理选择的账号信息进入下一步 autoRenewInfo
    const info: Ref<ChooseInfo> = ref({
        totalPrice: 0,
        count: 0,
        usersId: [],
        item: []
    });

    // 对选择参数进行处理下一步展示用
    const setInfo = (chooseInfo: any) => {
        info.value = chooseInfo;
        // 前端计算下一次过期时间
        const informationItem = chooseInfo.item;
        if (subscriptionType.value === 'featurePlan') {
            informationItem.NextExpireTime = moment(
                getNextExpireTime(informationItem.ExpireTime ? informationItem.ExpireTime : '--', chooseInfo.count)
            ).format('YYYY-MM-DD HH:mm:ss');
        } else if (subscriptionType.value !== 'active') {
            (chooseInfo.item as Array<UserItem>).forEach((element, index) => {
                if (subscriptionType.value === 'renewToDay') {
                    informationItem[index].NextExpireTime = `${chooseInfo.renewToDate as string} 23:59:59`;
                    const customizeForm = getCustomizeForm(informationItem[index].CustomizeForm);
                    informationItem[index].ShowNextExpireTime = moment(informationItem[index].NextExpireTime).format(customizeForm);
                } else {
                    const customizeForm = getCustomizeForm(informationItem[index].CustomizeForm);
                    informationItem[index].NextExpireTime = getNextExpireTime(
                        moment(element.ExpireTime, customizeForm).format('YYYY-MM-DD HH:mm:ss'),
                        chooseInfo.count
                    );
                    informationItem[index].ShowNextExpireTime = moment(informationItem[index].NextExpireTime).format(customizeForm);
                }
            });
        }
        info.value.item = informationItem;
        setActiveStep(2);
    };

    // 确认订单信息进入支付
    const orderID = ref('');
    const payJump = ref();

    const getOrderType = () => {
        let orderType = 0;
        switch (subscriptionType.value) {
            case 'active':
                orderType = 0;
                break;
            case 'featurePlan':
                if (Number((info.value.item as FeatureItem).FeeType) === typeForMonthly) {
                    orderType = 6;
                } else {
                    orderType = 5;
                }
                break;
            case 'renewToDay':
                orderType = 6;
                break;
            case 'renewToMonth':
                if (singleTypeGroup.includes(userType)) {
                    orderType = 3;
                } else {
                    orderType = 1;
                }
                break;
            default:
                break;
        }
        return orderType;
    };

    // 处理请求地址和参数
    const requestHandler = () => {
        let url = getCreateOrderUrl(userType, subscriptionType.value);
        const tempUserType: string = userType;
        if (isAutoRenew.value) {
            // @ts-ignore
            if (tempUserType === 'propertyCommunity') {
                url = autoRenewOpType.value === 'add' ? 'v3/web/community/subscription/createSubscription' : 'v3/web/community/subscription/editSubscription';
            } else if (tempUserType === 'propertyOffice') {
                url = autoRenewOpType.value === 'add' ? 'v3/web/office/subscription/createSubscription' : 'v3/web/office/subscription/editSubscription';
            }
        }

        const orderType = getOrderType();
        if (subscriptionType.value === 'featurePlan') {
            const params = {
                Type: orderType,
                Total: info.value.totalPrice,
                Count: info.value.count,
                Users: info.value.usersId,
                Manage: userType === 'distributorOffice' ? projectId.split(';')[1] : ''
            };
            return { url, params };
        }
        let params: {[key: string]: any} = {
            Type: orderType,
            Total: info.value.totalPrice,
            Count: info.value.count,
            Users: JSON.stringify(info.value.usersId)
        };

        if (communityTypeGroup.includes(userType)) {
            if (subscriptionType.value === 'renewToDay') {
                params.Type = 6;
                params.Count = 1;
                params.NextTime = info.value.renewToDate;
            }
        }

        // autoRenew-0:付费参数构造
        // @ts-ignore
        if (isAutoRenew.value && info.value.autoRenewInfo) {
            // @ts-ignore
            params = {
                // @ts-ignore
                Users: info.value.autoRenewInfo.Users,
                // @ts-ignore
                IntervalType: info.value.autoRenewInfo.IntervalType,
                // @ts-ignore
                Cycles: info.value.autoRenewInfo.Cycles,
                // @ts-ignore
                StartTime: info.value.autoRenewInfo.StartTime,
                // @ts-ignore
                TotalPrice: info.value.autoRenewInfo.TotalPrice
                // SubscriptionUUID: autoRenewInfo.SubscriptionUUID,
                // InstallerUUID: props.userType === 'distributorOffice' ? props.projectId.split(';')[0] : props.projectId
            };
            if (autoRenewOpType.value === 'edit') {
                // @ts-ignore
                params.SubscriptionUUID = info.value.autoRenewInfo.SubscriptionUUID;
            }
            // if (projectId) {
            //     params.InstallerUUID = userType === 'distributorOffice' ? projectId.split(';')[0] : projectId;
            // }
        }

        return { url, params };
    };
    // 创建订单
    const createOrder = (
        isBillInfoFilled: boolean
    ) => {
        if (!isBillInfoFilled) {
            // 账单信息未全部填写不可支付
            Notice.messageBox('alert', WordList.ProperAllTextCompleteBilling, WordList.TabelDeleteTitle, 'warning')(() => false);
        } else {
            const { url, params } = requestHandler();

            HttpRequest.post(url, params, [(data: {
                orderID: string;
                bmurl: string;
            }) => {
                // console.log('data', data);
                orderID.value = data.orderID;
                setActiveStep(3);
                // @ts-ignore
                payJump.value = data.bmurl || data.data.bmurl;
                window.open(payJump.value);
            }, false]);
        }
    };

    // 下载发票
    const invoice = () => {
        const url = getReceiptUrl(userType);
        subscriptionApi.downInvoice(orderID.value, url);
    };

    return {
        setInfo,
        createOrder,
        invoice,
        orderID,
        payJump,
        info
    };
};

export default initPay;
