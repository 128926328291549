
import {
    defineComponent, PropType, reactive, ref, defineAsyncComponent
} from 'vue';
import { list } from '@/components/common/list';
import billingInfo from '@/components/view/common/billing-info';
import { updateSortField, getInitSortField, getSortImg } from '@/methods/sort-table';
import { initBillInfo } from './init-billInfo';
import { initList } from './init-list';
import {
    ChooseInfo, SubscriptionType, UserType, UserItem
} from '../../all.type';
import activityProp from '../activity-prop.vue';
import { renewType, FeeType } from '../../init-all-data';

export default defineComponent({
    components: {
        list,
        billingInfo,
        activityProp
    },
    props: {
        info: {
            required: true,
            type: Object as PropType<ChooseInfo>
        },
        subscriptionType: {
            required: true,
            type: String as PropType<SubscriptionType>
        },
        userType: {
            required: true,
            type: String as PropType<UserType>
        },
        isBatch: {
            type: Boolean,
            default: false
        }
    },
    setup(props, { emit }) {
        // 初始化billInfo数据
        const {
            isBillInfoFilled,
            billInfoFilled
        } = initBillInfo();
        // @ts-ignore
        const isAutoRenew = (props.subscriptionType === 'renewToDay' || props.subscriptionType === 'renewToMonth') && typeof props.info.autoRenewInfo.IntervalType === 'number' && props.info.autoRenewInfo.IntervalType !== 100;

        const autoRenewConfigInfoComponent = {
            propertyCommunity: defineAsyncComponent(() => import('./auto-renew-config-info-community.vue')),
            propertyOffice: defineAsyncComponent(() => import('./auto-renew-config-info-office.vue'))
        };
        const {
            listHeader,
            userInfo,
            updateToList
        } = initList(props.userType, props.subscriptionType, props.info, props.isBatch, isAutoRenew);
        const userInfoList = ref(userInfo);
        // 营销活动信息
        const activity: Array<string> = [];
        const activityIds: Array<string> = [];
        let saveFee = 0;
        if (props.subscriptionType !== 'featurePlan') {
            const userItem = props.info.item as UserItem[];
            userItem.forEach((item) => {
                if (item.Introduction && !activityIds.includes(item.ActivityId)) {
                    activity.push(item.Introduction);
                    activityIds.push(item.ActivityId);
                }

                saveFee += Number(item.SaveFee);
            });
        }

        // 点击支付的控制
        const agree = ref(false);
        const pay = () => {
            emit('pay', isBillInfoFilled.value);
        };
        // 初始化排序
        if (['distributorCom', 'community', 'propertyCommunity'].includes(props.userType)) {
            initSort();
        }

        function initSort() {
            const initSortRes = getInitSortField(`${props.userType}SubscriptionInformation`);
            const sortFn = (obj1: any, obj2: any) => {
                const value1 = obj1[`${initSortRes.sortField}`];
                const value2 = obj2[`${initSortRes.sortField}`];
                if (value1 && value2 && value1.localeCompare(value2) > 0) {
                    return 1;
                } if (value1 && value2 && value1.localeCompare(value2) < 0) {
                    return -1;
                }
                return 0;
            };

            if (initSortRes.sort === 'desc') {
                userInfoList.value.sort(sortFn).reverse();
            } else {
                userInfoList.value.sort(sortFn);
            }
            updateToList.value += 1;
        }
        // 获取排序方式， 更新表格数据
        function getSortRes(table: string, sortField: string) {
            const {
                sort
            } = updateSortField(table, sortField);
            const sortFn = (obj1: any, obj2: any) => {
                const value1 = obj1[`${sortField}`];
                const value2 = obj2[`${sortField}`];
                if (value1.localeCompare(value2) > 0) {
                    return 1;
                } if (value1.localeCompare(value2) < 0) {
                    return -1;
                }
                return 0;
            };
            if (sort === 'desc') {
                userInfoList.value.sort(sortFn).reverse();
            } else {
                userInfoList.value.sort(sortFn);
            }
            updateToList.value += 1;
        }
        function generateAgreementWord() {
            const word = !isAutoRenew ? WordList.OrderAgree : WordList.AutoPayAgreeTips;
            const uri = `${window.location.href.split('/#/')[0]}/#/agreement/user-agreement`;
            return word.format(uri);
        }
        return {
            isBillInfoFilled,
            billInfoFilled,
            listHeader,
            userInfo,
            userInfoList,
            updateToList,
            renewType,
            activity,
            activityIds,
            saveFee,
            agree,
            pay,
            FeeType,
            getSortImg,
            getSortRes,
            isAutoRenew,
            autoRenewConfigInfoComponent,
            generateAgreementWord
        };
    }
});
