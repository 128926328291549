
import { defineComponent, onBeforeUnmount } from 'vue';

export default defineComponent({
    emits: ['success', 'failed'],
    props: {
        payJumpUrl: {
            type: String,
            required: true
        },
        title: {
            type: String,
            default: WordList.ProperAllTextPayProgressing
        },
        jumpTips: {
            type: String,
            default: WordList.ProperAllTextPayJump
        }
    },
    setup(props, { emit }) {
        const reload = (data: { data: string }) => {
            if (data.data === 'reload') {
                emit('success');
            } else if (data.data === 'failed' || data.data === 'autoRenewFailed') {
                emit('failed');
            } else if (data.data === 'autoRenewToList') {
                window.location.reload();
            } else if (data.data === 'autoRenewComplete') {
                emit('success');
            }
        };

        window.addEventListener('message', reload);

        onBeforeUnmount(() => {
            window.removeEventListener('message', reload);
        });

        const payJump = () => {
            window.open(props.payJumpUrl);
        };
        return {
            payJump
        };
    }
});
