
import { defineComponent } from 'vue';
import baseSubscription from '@/components/view/common/subscription';
import changeTextByScene from '@/methods/setText';
import { isOffice } from '@/methods/role';

export default defineComponent({
    components: {
        baseSubscription
    },
    setup() {
        changeTextByScene();
        const userType = isOffice() ? 'office' : 'community';

        return {
            userType
        };
    }
});
