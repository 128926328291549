import { createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock("div", {
    onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('click'))),
    class: "card-text enable"
  }, [
    _createVNode("img", { src: _ctx.img }, null, 8, ["src"]),
    _createVNode("p", null, _toDisplayString(_ctx.title), 1)
  ]))
}