
import { defineComponent } from 'vue';
import './index.less';

export default defineComponent({
    emits: ['click'],
    props: {
        title: {
            required: true,
            type: String
        },
        img: {
            required: true,
            type: String
        }
    }
});
