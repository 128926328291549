
import { defineComponent } from 'vue';

export default defineComponent({
    props: {
        activity: {
            required: true,
            type: Array
        },
        text: {
            required: true,
            type: String
        },
        saveFee: {
            required: true
        },
        month: {
            default: 1
        }
    }
});
