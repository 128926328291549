import { ref, reactive } from 'vue';
import {
    UserType, SubscriptionType, ChooseInfo, UserItem, ReturnHeaderList, FeatureItem
} from '../../all.type';
import {
    renewType, getUserHeader, getFeaturePlanHeaders, typeForMonthly
} from '../../init-all-data';

const initList = (
    userType: UserType,
    subscriptionType: SubscriptionType,
    info: ChooseInfo,
    isBatch: boolean,
    isAutoRenew?: boolean
) => {
    // 控制列表刷新
    const updateToList = ref(0);
    // 表头
    let listHeader = reactive<ReturnHeaderList>({});
    // 选择的用户列表
    let userInfo = reactive<object[]>([]);
    if (subscriptionType === 'featurePlan') {
        const featureItem = info.item as FeatureItem;
        const headerType = Number(featureItem.FeeType) === typeForMonthly ? 'monthly' : 'oneTime';
        listHeader = getFeaturePlanHeaders(headerType);
        userInfo = [featureItem];
    } else {
        // 激活不显示下一次过期时间，所以表头同第二步一样就好
        listHeader = getUserHeader(
            userType,
            isBatch,
            subscriptionType,
            renewType.includes(subscriptionType) ? 'info' : 'choose'
        );
        if (isAutoRenew) {
            const index = (listHeader as any[]).findIndex((item) => item.name === 'ShowNextExpireTime');
            if (index > -1) {
                (listHeader as any[]).splice(index, 1);
            }
        }
        userInfo = info.item as Array<UserItem>;
    }
    return {
        listHeader,
        userInfo,
        updateToList
    };
};
export default null;
export {
    initList
};
