
import {
    defineComponent, PropType, ref, Ref, computed, defineAsyncComponent
} from 'vue';
import { useRoute } from 'vue-router';
import { GetListForPmSubscription } from '@/api/community/subscription';
import AutoRenewSuccess from './module/auto-renew-success.vue';
import AutoRenewFailed from './module/auto-renew-failed.vue';
import initStep from './init-step';
import initPay from './init-pay';
import {
    UserType, SubscriptionType
} from './all.type';
import ChooseType from './module/choose-type/choose-type.vue';
import PurchaseDetail from './module/purchase-detail/index.vue';
import Information from './module/Information/information.vue';
import Processing from './module/pay/processing.vue';
import PaySuccess from './module/pay/pay-success.vue';
import PayFailed from './module/pay/pay-failed.vue';
import { communityTypeGroup } from './init-all-data';

export default defineComponent({
    components: {
        ChooseType,
        PurchaseDetail,
        Information,
        Processing,
        PaySuccess,
        PayFailed,
        AutoRenewSuccess,
        AutoRenewFailed
    },
    props: {
        // TODO dis角色操作需要记录操作的projectId 批量待定修改
        projectId: {
            type: String,
            default: ''
        },
        userType: {
            required: true,
            type: String as PropType<UserType>
        },
        // 是否是批量操作
        isBatch: {
            type: Boolean,
            default: false
        },
        initPurchaseDetailsData: {
            type: Object,
            default: () => ({})
        }
    },
    setup(props) {
        const route = useRoute();
        // const {
        //     activeStep,
        //     setActiveStep,
        //     backStep
        // } = initStep();
        // 激活步骤
        const activeStep = ref(0);

        // 设置步骤
        const setActiveStep = (step: number) => {
            let stepTemp = step;
            if (step === 4 && isAutoRenew.value) {
                stepTemp = 6;
            } else if (step === 5 && isAutoRenew.value) {
                stepTemp = 7;
            }
            activeStep.value = stepTemp;
        };

        // 步骤-1
        const backStep = () => {
            if (processLastStep.value && activeStep.value === 1) {
                changeStepHandler({ type: 'back', to: processLastStep.value });
                processLastStep.value = '';
                // autoRenew1-0-1:清除编辑初始化autoRenew数据
                initPurchaseDetailsDataTemp.value = {};
                return;
            }
            if (activeStep.value !== 0) {
                activeStep.value -= 1;
            } else {
                // TODO 批量操作放回到home页
            }
        };

        const subscriptionType: Ref<SubscriptionType> = ref('active');

        // 选择操作类型进入下一步
        const setType = (type: SubscriptionType) => {
            subscriptionType.value = type;
            // TODO 先按方法设置，考虑到后期回退可能需要操作，如果后期没有就删除该方法赋值操作
            setActiveStep(1);
        };

        const subType = route.query.subscriptionType;

        if (subType) {
            if (subType === 'renew') {
                let renewType: SubscriptionType = 'renewToMonth';
                if (communityTypeGroup.includes(props.userType)) {
                    renewType = 'renewToDay';
                }
                setType(renewType);
            }
        }
        // autoRenew-1-0:编辑初始化autoRenew数据
        const initPurchaseDetailsDataTemp = ref(props.initPurchaseDetailsData);
        // autoRenew-1-0-3:
        const autoRenewOpType = computed(() => (Object.keys(initPurchaseDetailsDataTemp.value).length > 0 ? 'edit' : 'add'));
        // autoRenew-0:是不是在自动续费，要purchase-detail-index步骤完成才能使用这个变量,注意：后面加新的IntervalType类型需要修改
        // @ts-ignore
        const isAutoRenew = computed(() => (subscriptionType.value === 'renewToDay' || subscriptionType.value === 'renewToMonth') && info.value.autoRenewInfo && (info.value.autoRenewInfo.IntervalType === 0 || info.value.autoRenewInfo.IntervalType === 3));

        // autoRenew-0 back方法
        const stepStack = ['process'];
        // lastStep现在只有autoPayInfo使用上一级肯定是process
        const lastStep = ref('');
        const step = ref('process');
        const processLastStep = ref('');
        // autoRenew-1-0:编辑初始化autoRenew数据
        // autoRenew-1-0-3:
        function changeStepHandler(stepData: {type: string; to: string; data?: any}) {
            let stepStackLen = stepStack.length;
            if (stepData.type === 'forward') {
                // autoRenew1-0-2:赋值编辑初始化autoRenew数据
                if (stepData.to.indexOf('process-') > -1) {
                    processLastStep.value = stepStack[stepStackLen - 1];
                    activeStep.value = +stepData.to.split('-')[1];
                    stepStack.push('process');
                    lastStep.value = 'process';
                    initPurchaseDetailsDataTemp.value = stepData.data;
                    let renewType: SubscriptionType = 'renewToMonth';
                    if (communityTypeGroup.includes(props.userType)) {
                        renewType = 'renewToDay';
                    }
                    subscriptionType.value = renewType;
                } else {
                    lastStep.value = stepStack[stepStackLen - 1];
                    stepStack.push(stepData.to);
                }
            } else {
                stepStack.splice(stepStackLen - 1, 1);
                if (stepData.to === 'process' && activeStep.value !== 0) {
                    activeStep.value = 0;
                }
            }
            stepStackLen = stepStack.length;
            step.value = stepStack[stepStackLen - 1];
            console.log('step.value', step.value, stepStack);
            if (stepData.to === 'autoPayInfo' && stepData.data) {
                initToAutoPayInfo(stepData.data as GetListForPmSubscription['row'][0]);
            }
        }

        const autoPayItemData = ref<GetListForPmSubscription['row'][0]>();
        function initToAutoPayInfo(item: GetListForPmSubscription['row'][0]) {
            autoPayItemData.value = item;
        }
        const autoPayInfoComponents = {
            propertyCommunity: defineAsyncComponent(() => import('./module/auto-pay-info-community.vue')),
            propertyOffice: defineAsyncComponent(() => import('./module/auto-pay-info-office.vue'))
        };

        const {
            setInfo,
            createOrder,
            invoice,
            payJump,
            info
        } = initPay(
            setActiveStep,
            subscriptionType,
            props.userType,
            props.projectId,
            props.isBatch,
            isAutoRenew,
            autoRenewOpType
        );

        return {
            activeStep,
            setType,
            subscriptionType,
            setInfo,
            backStep,
            createOrder,
            invoice,
            payJump,
            info,
            initPurchaseDetailsDataTemp,
            autoRenewOpType,
            lastStep,
            step,
            changeStepHandler,
            autoPayItemData,
            autoPayInfoComponents,
            isAutoRenew,
            setActiveStep
        };
    }
});
