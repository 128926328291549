import { ref } from 'vue';
import { discountUnitTo100, discountUnitEx100, discountTotalCount } from '@/methods/count';
import { UserItem } from '../../all.type';

const initActivityOrRenewToMonth = () => {
    // 倍数：按月续费是续费的月数，激活固定为1
    const count = ref(1);
    // 单价：月费或者激活费
    const unitPrice = ref(0);
    // 优惠金额
    const savePrice = ref(0);
    // 总价
    const totalPrice = ref(0);
    // 优惠活动记录
    const activity = ref<Array<string>>([]);
    const activityIds = ref<Array<string>>([]);

    // 计算金额
    const calculation = (
        checkRows: Array<UserItem>
    ) => {
        // 同一个营销活动只显示一次，注意精度丢失
        const activityInclude: Array<string> = [];
        const activityIdInclude: Array<string> = [];
        let unitPriceDiscount = 0;
        let savePriceDiscount = 0;
        checkRows.forEach((item) => {
            const amount = discountUnitTo100(Number(item.Amcount));
            unitPriceDiscount += parseFloat(amount.toString());
            if (item.Introduction && !activityIdInclude.includes(item.ActivityId)) {
                activityInclude.push(item.Introduction);
                activityIdInclude.push(item.ActivityId);
            }

            savePriceDiscount += discountUnitEx100(discountUnitTo100(Number(item.SaveFee)));
        });

        unitPrice.value = discountUnitEx100(unitPriceDiscount);
        savePrice.value = savePriceDiscount;
        activity.value = activityInclude;
        activityIds.value = activityIdInclude;
        totalPrice.value = discountTotalCount(unitPrice.value, count.value);
    };

    return {
        unitPrice,
        count,
        savePrice,
        totalPrice,
        activity,
        activityIds,
        calculation
    };
};

export default null;
export {
    initActivityOrRenewToMonth
};